<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">{{ id == 'new' ? 'INCLUIR' : 'EDITAR' }}</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nome</label>
                <md-input v-model="user.name" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Email</label>
                <md-input v-model="user.email" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Status</label>
                <md-select v-model="user.isActive">
                  <md-option :value="true">ATIVO</md-option>
                  <md-option :value="false">INATIVO</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Telefone</label>
                <md-input v-model="user.phoneNumber" type="text"></md-input>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-default" @click="back()">CANCELAR</md-button>
          <md-button class="md-success" @click="save()">SALVAR</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">CLIENTES </h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-80">
              <md-field>
                <label>Cliente</label>
                <md-select v-model="customerId">
                  <md-option :value="0">Selecione</md-option>
                  <md-option v-for="item of customers" :value="item.id" :key="item.id">{{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Tipo</label>
                <md-select v-model="type">
                  <md-option :value="0">Selecione</md-option>
                  <md-option v-for="item of tiposUsuario" :value="item.id" :key="item.id">{{ item.description }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <md-card-actions md-alignment="right">
            <md-button class="md-info" @click="addCustomer()">ADICIONAR</md-button>
          </md-card-actions>
          <md-table v-model="user.customers" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Nome">{{ item.customer.name | uppercase }}</md-table-cell>
              <md-table-cell md-label="Cidade">{{ item.customer.city }} - {{item.customer.state}}</md-table-cell>
              <md-table-cell md-label="Tipo" class="text-center">
                <div class="text-center h6">
                  {{ typeDescription(item.type) }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Ações" class="text-center">
                <div>
                  <md-button class="md-danger md-sm" style="min-width: 60px !important;" @click="deleteItem(item)">
                    Excluir
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>

</template>
<script>
import api from './../../services/api';
import Swal from 'sweetalert2';

export default {
  components: {},
  data() {
    return {
      id: null,
      customerId: 0,
      type: 0,
      user: {
        id: 0,
        name: '',
        email: '',
        isActive: true,
        phoneNumber: '',
        avatar: '',
        customers: []
      },
      customers: [],
      tiposUsuario: [
        {
          id: 1,
          description: 'PROPRIETÁRIO'
        },
        {
          id: 2,
          description: 'GERENTE'
        },
        {
          id: 3,
          description: 'RECEPTIONISTA'
        }
      ]
    };
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id != 'new') {
      this.getUser();
    }
    this.getCustomers();
  },
  methods: {    
    typeDescription(id) {
      return this.tiposUsuario.find(i => i.id == id).description;
    },
    deleteItem(item) {
      const index = this.user.customers.indexOf(item);
      Swal.fire({
        title: `Excluir?`,
        text: `${item.customer.name}`,
        showCancelButton: true,
        confirmButtonClass: "md-button md-info",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "SIM",
        cancelButtonText: "NÂO",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.user.customers.splice(index, 1);
        }
      });
    },
    addCustomer() {
      const { customerId, type } = this;

      if (!customerId) {
        this.notify('Cliente não informado');
        return;
      }

      if (!type) {
        this.notify('Tipo de Usuário não informado');
        return;
      }

      const customer = this.customers.find(i => i.id == customerId);

      this.user.customers.push({
        id: null,
        customer,
        type: type
      });

      this.customerId = 0;
      this.type = 0;
    },
    async save() {
      const { name, email } = this.user;

      if (!name) {
        this.notify('Nome não informado');
        return;
      }

      if (!email) {
        this.notify('Email não informado');
        return;
      }

      try {
        this.id == 'new' ?
          await api.post('/users', { ...this.user }) :
          await api.patch(`/users/${this.id}`, { ...this.user });

        this.back();
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        const { message } = data;
        this.notify(message ?? data.toString());
      }
    },
    back() {
      this.$router.push({ path: '/admin/users', replace: true })
    },
    getCustomers() {
      api.get(`/customers`)
        .then(value => {
          const { data } = value;
          this.customers = data;
        })
        .catch(error => console.log(error));
    },
    getUser() {
      api.get(`/users/${this.id}`)
        .then(value => {
          const { data } = value;
          this.user = data;
        })
        .catch(error => console.log(error));
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
