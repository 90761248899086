<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-card header-color="green">
        <h4 slot="title" class="title">Bitz Softwares</h4>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Senha...</label>
          <md-input v-model="password" type="password"></md-input>
        </md-field>
        <md-button slot="footer" class="md-primary md-success md-lg" @click="onLogin">
          Entrar
        </md-button>
      </login-card>
    </div>
  </div>

</template>
<script>

import { LoginCard } from "@/components";
import api from './../../../services/api';
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    async onLogin() {
      try {
        const { email, password } = this;
        const response = await api.post('/auth/admin/signin', {
          email,
          password
        });
        const { data } = response;
        localStorage.setItem('access_token', data.token);
        this.$router.push({ path: '/admin/dashboard', params: { user: data } });
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        const message = data ? data.message : e.message;
        this.notify(message);
      }
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  }
};
</script>

<style>
</style>
