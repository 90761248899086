import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";

import Customers from '@/pages/bitz/Customers.vue'
import Customer from '@/pages/bitz/Customer.vue'
import Usuarios from '@/pages/bitz/Users.vue'
import Usuario from '@/pages/bitz/User.vue'

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  redirect: '/login',
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
};

const routes = [ 
  authPages,
  {
    path: "/admin",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },     
      {
        path: "customers",
        name: "Clientes",
        component: Customers,
      },
      {
        path: "customer/:id",
        name: "Cliente",
        component: Customer,
      },
      {
        path: "users",
        name: "Usuários",
        component: Usuarios,
      },
      {
        path: "user/:id",
        name: "Usuário",
        component: Usuario,
      },
      
      {
        path: "user",
        name: "User Page",
        components: { default: User },
      },
    ],
  },
];

export default routes;
