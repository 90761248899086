<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <md-card>
            <md-card-header class="md-card-header-text md-card-header-green">
              <div class="card-text">
                <h4 class="title">{{ id == 'new' ? 'INCLUIR' : 'EDITAR' }}</h4>
              </div>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-80">
                  <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>Nome</label>
                      <md-input v-model="customer.name" type="text" class="md-uppercase"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-20">
                  <md-field>
                    <label>Status</label>
                    <md-select v-model="customer.isActive">
                      <md-option :value="true">ATIVO</md-option>
                      <md-option :value="false">INATIVO</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>Email</label>
                      <md-input v-model="customer.email" type="text" class="md-lowercase"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Telefone</label>
                    <md-input v-model="customer.phoneNumber" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider name="document" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>Documento</label>
                      <md-input v-model="customer.document" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Site</label>
                    <md-input v-model="customer.website" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Endereço</label>
                    <md-input v-model="customer.address" type="text"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Bairro</label>
                    <md-input v-model="customer.neighborhood" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Cidade</label>
                    <md-input v-model="customer.city" type="text"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Estado</label>
                    <md-input v-model="customer.state" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider name="host" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>IP Externo</label>
                      <md-input v-model="customer.host" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider name="port" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>Porta(Oracle)</label>
                      <md-input v-model="customer.port" type="number"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="right">
              <md-button class="md-default" @click="back()">CANCELAR</md-button>
              <md-button class="md-success" type="submit">SALVAR</md-button>
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>

  </div>

</template>
<script>
import api from './../../services/api';
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      id: null,
      customer: {
        name: '',
        email: '',
        phoneNumber: '',
        website: '',
        document: '',
        address: '',
        neighborhood: '',
        city: '',
        state: '',
        isActive: true,
        logo: '',
        host: '',
        port: 1521,
      },
    }
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id != 'new') {
      this.getCustomer();
    }
  },
  methods: {
    submit() {
      this.save();
    },
    async save() {      
      try {
        this.id == 'new' ?
          await api.post('/customers', { ...this.customer }) :
          await api.patch(`/customers/${this.id}`, { ...this.customer });

        this.back();
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        const { message } = data;
        this.notify(message ?? data.toString());
      }
    },
    back() {
      this.$router.push({ path: '/admin/customers', replace: true })
    },    
    getCustomer() {
      api.get(`/customers/${this.id}`)
        .then(value => {
          const { data } = value;
          this.customer = data;
        })
        .catch(error => console.log(error));
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
