<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" @click.capture="clicked">
        <span>
          {{ user.nome ? user.nome : 'BITZ' }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>              
              <li>
                <a href="#/admin/user">
                  <span class="sidebar-mini">EP</span>
                  <span class="sidebar-normal">Meu Perfil</span>
                </a>
              </li>
              <li>                
                <a href="#" @click="logOut()">
                  <span class="sidebar-mini">S</span>
                  <span class="sidebar-normal">Sair</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    CollapseTransition,
  },
  props: {
    user: {
      nome: '123',
    },
    title: {
      type: String,
      default: "Tania Andrew",
    },
    avatar: {
      type: String,
      default: "./img/faces/marc.jpg",
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    logOut(){
      localStorage.removeItem('access_token');
      this.$router.push({ path: '/login', replace: true })
    }
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
