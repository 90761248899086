<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title pull-right">
            <router-link to="/admin/user/new">
              <md-button class="md-success md-sm ">
                Incluir
                <span class="md-label">
                  <md-icon class="material-icons">plus_one</md-icon>
                </span>
              </md-button>
            </router-link>
          </h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Nome">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Email">
                {{ item.email }}
              </md-table-cell>
              <md-table-cell md-label="Alt. Senha">
                {{ item.mustChangePassword ? 'SIM' : 'NÃO' }}
              </md-table-cell>
              <md-table-cell md-label="Status">
                {{ item.isActive ? 'ATIVO' : 'INATIVO' }}
              </md-table-cell>
              <md-table-cell md-label="Clientes">
                {{ item.customers.length }}
              </md-table-cell>
              <md-table-cell md-label="Ações">
                <router-link :to="'/admin/user/' + item.id">
                  <md-button class="md-just-icon md-info">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
                <md-button :class="'md-just-icon ' + (item.isActive ? 'md-primary' : 'md-default')"
                  @click="setStatus(item)">
                  <md-icon>{{ item.isActive ? 'toggle_off' : 'toggle_on' }}</md-icon>
                </md-button>
                <md-button class="md-just-icon md-danger" @click="deleteItem(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>

import api from './../../services/api';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api.get('/users')
        .then(value => {
          const { data } = value;
          this.tableData = data;
        })
        .catch(error => console.log(error));
    },
    setStatus(item) {
      Swal.fire({
        title: `${item.isActive ? 'Inativar' : 'Reativar'} ${item.name}?`,
        text: `${item.isActive ? 'Ao inativar o usuário ele não terá mais acesso a plataforma!' : 'Ao reativar o usuário ele poderá acessar novamente a plataforma!'}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-info",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "SIM",
        cancelButtonText: "NÂO",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.put(`/users/${item.id}/status`, {
            isActive: !item.isActive
          })
            .then(response => {
              const { data } = response;
              item.isActive = data.isActive;
            })
            .catch(error => console.log(error));
        }
      });
    },
    deleteItem(item) {
      Swal.fire({
        title: `Excluir ${item.name}?`,
        text: `Ao excluir o usuário ele não terá mais acesso a plataforma!`,
        showCancelButton: true,
        confirmButtonClass: "md-button md-info",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "SIM",
        cancelButtonText: "NÂO",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.delete(`/users/${item.id}`)
            .then(value => this.getData())
            .catch(error => console.log(error));
        }
      });
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
