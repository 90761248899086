
import axios from 'axios'

export default axios.create({
    baseURL: process.env.NODE_ENV === "production" ?
        'https://api.bitz-motel.bitzsoftwares.com.br/' :
        'http://localhost:3335',    
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});